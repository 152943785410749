import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Typography, Grid } from '@mui/material';
import { setIsAddNew, setIsEdit } from '../system/redux/reducers/globals';
import { openDeleteModal } from '../system/redux/reducers/modal';
import { db } from '../system/firebase/index';
import {
    updateDoc,
    doc,
    collection,
    setDoc,
    getDoc,
    onSnapshot,
    query,
} from 'firebase/firestore';
import InputUnstyled from '@mui/base/InputUnstyled';
import Loading from './Loading';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import ConfirmDeleteListingModal from './ConfirmDeleteListingModal';
import UrlConstruct from './UrlConstruct';
import QrCode from './QrCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight,
    faArrowUpRightFromSquare,
    faCheck,
    faChevronDown,
    faChevronUp,
    faPaintBrush,
    faPlus,
    faTrash,
    faX,
} from '@fortawesome/free-solid-svg-icons';
import { constructDownloadName, formatDate } from '../helpers/functions';
import {
    FILE_FOLDERS,
    QR_DESTINATION_TYPES,
    QR_DESTINATION_TYPE_FILE_TYPES,
    QR_DESTINATION_TYPE_LABELS,
    QR_VIEWS,
} from '../helpers/constants';
import IconLink from './IconLink';
import FileUploadModal from './FileUploadModal';
import QrItemContainer from './QrItemContainer';
import QrItemHeader from './QrItemHeader';
import QrItemInner from './QrItemInner';
import QrItemFooter from './QrItemFooter';
import Auto from './Autocomplete';

const Input = styled(InputUnstyled)(({ theme, light }) => ({
    'input, textarea': {
        backgroundColor: 'white',
        border: 'none',
        color: theme.palette.black.main,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        fontWeight: light ? 300 : 400,
        margin: ' 0 0 10px',
        outline: 'none',
        padding: '0 12px',
        width: '100%',

        '&::placeholder': {
            color: theme.palette.black.main,
            textTransform: 'uppercase',
        },
    },
}));

const DestinationTypePicker = ({ destinationType, setNewDestinationType }) => {
    const theme = useTheme();
    const [showDestinationTypePicker, setShowDestinationTypePicker] =
        useState(false);

    const handleDestinationTypeChange = (newDestinationType) => {
        setNewDestinationType(newDestinationType);
        setShowDestinationTypePicker(false);
    };

    return (
        <Box ml={4} display="flex" flexDirection="column">
            <Typography
                textTransform="uppercase"
                fontWeight="bold"
                onClick={() =>
                    setShowDestinationTypePicker(!showDestinationTypePicker)
                }
                sx={{ cursor: 'pointer' }}
            >
                <span style={{ textDecoration: 'underline' }}>
                    {QR_DESTINATION_TYPE_LABELS[destinationType]}
                </span>
                <FontAwesomeIcon
                    icon={
                        showDestinationTypePicker ? faChevronUp : faChevronDown
                    }
                    style={{ marginLeft: theme.spacing(1) }}
                />
            </Typography>
            {showDestinationTypePicker && (
                <>
                    {Object.entries(QR_DESTINATION_TYPE_LABELS).map(
                        ([key, label]) =>
                            key !== destinationType ? (
                                <Typography
                                    key={key}
                                    textTransform="uppercase"
                                    onClick={() =>
                                        handleDestinationTypeChange(key)
                                    }
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {label}
                                </Typography>
                            ) : null
                    )}
                </>
            )}
        </Box>
    );
};

const LabelledInputContainer = ({
    children,
    label,
    url,
    urlKey,
    isBordered = false,
}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexWrap="nowrap"
            borderBottom={
                isBordered ? `1px solid ${theme.palette.grey.lighter}` : null
            }
            mb={isBordered ? 3 : 0.5}
        >
            <Typography fontWeight="bold" textTransform="uppercase">
                {label}:
            </Typography>
            {(url || urlKey) && (
                <UrlConstruct
                    url={url}
                    urlKey={urlKey}
                    sx={{ marginLeft: theme.spacing(2) }}
                >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </UrlConstruct>
            )}
            <Box
                flexGrow={1}
                overflow="hidden"
                sx={{
                    '& input, & p': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

const LabelledInput = ({
    label,
    name,
    value,
    placeholder,
    errors,
    register,
    updateInputs,
    isUrl = false,
    isBordered = false,
    pat = false,
    mes,
}) => {
    const theme = useTheme();

    return (
        <LabelledInputContainer
            label={label}
            url={isUrl ? value : null}
            isBordered={isBordered}
        >
            <Box flexGrow={1}>
                <Input
                    {...register(name, {
                        required: 'Cannot be empty',
                        pattern: {
                            value: pat ? /^[a-zA-Z0-9]+$/ : /.*$/,
                            message: mes,
                        },
                    })}
                    autoComplete="off"
                    name={name}
                    onChange={(e) => updateInputs(e, name)}
                    placeholder={placeholder}
                    type="text"
                    value={value}
                    sx={{
                        input: {
                            paddingLeft: isUrl ? theme.spacing(1) : null,
                            marginBottom: 0,
                        },
                    }}
                />
            </Box>
            {errors && errors[name] && (
                <Box fontSize="12px" color="red.main" fontWeight="bold">
                    {errors[name].message}
                </Box>
            )}
        </LabelledInputContainer>
    );
};

const LabelledFileInput = ({
    label,
    file,
    uploadType,
    showFileUploadModal,
    setShowFileUploadModal,
    handleFileUpload,
    isBordered = false,
}) => {
    return (
        <>
            <LabelledInputContainer
                label={label}
                url={file && file.cachedUrl ? file.cachedUrl : null}
                isBordered={isBordered}
            >
                <Typography flexGrow={1} ml={1}>
                    {file?.filename ?? 'No file selected'}
                </Typography>
            </LabelledInputContainer>
            <Box mt={1}>
                <IconLink
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    iconSide="left"
                    text={`Upload ${QR_DESTINATION_TYPE_LABELS[uploadType]}`}
                    isFooterLink
                    onClick={() => setShowFileUploadModal(true)}
                />
                <FileUploadModal
                    open={showFileUploadModal}
                    setParentOpen={setShowFileUploadModal}
                    parentFileHandler={handleFileUpload}
                    label={QR_DESTINATION_TYPE_LABELS[uploadType]}
                    uploadCollection={FILE_FOLDERS.FILES}
                    fileType={QR_DESTINATION_TYPE_FILE_TYPES[uploadType]}
                />
            </Box>
        </>
    );
};

const LabelledUrlInput = ({ label, url, urlKey, isBordered = false }) => {
    return (
        <LabelledInputContainer
            label={label}
            url={url}
            urlKey={urlKey}
            isBordered={isBordered}
        >
            <Typography flexGrow={1} ml={1}>
                <UrlConstruct url={url} urlKey={urlKey} />
            </Typography>
        </LabelledInputContainer>
    );
};

const QrViewAddEdit = ({
    dispatch,
    setParentView,
    data = false,
    qrCode,
    type,
}) => {
    const theme = useTheme();
    const [usedUrlKeys, setUsedUrlKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [destinationFile, setDestinationFile] = useState(null);
    const [catOptions, setCatOptions] = useState(false);

    useEffect(() => {
        const userRef = doc(db, 'campaign', 'tags');
        const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
            const tags = userSnapshot.data();
            setCatOptions(tags);
        });
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const q = query(collection(db, 'listings'));
        onSnapshot(q, (snapshot) => {
            const output = [];
            snapshot.docs.map((doc) => {
                const listingData = doc.data();

                return output.push(listingData.urlKey);
            });
            setUsedUrlKeys(output);
        });
    }, []);

    // States
    const [inputs, setInputs] = useState({
        name: '',
        urlKey: '',
        campaign: '',
        destinationType: QR_DESTINATION_TYPES.URL,
        destination: '',
        date_added: '',
        visits: 0,
    });

    useEffect(() => {
        if (data) {
            setInputs({
                name: data.name || '',
                urlKey: data.urlKey || '',
                campaign: data.campaign || '',
                destinationType:
                    data.destinationType || QR_DESTINATION_TYPES.URL,
                destination: data.destination || '',
                date_added: data.date_added || '',
                visits: parseInt(data.visits) || 0,
            });
        }
        setLoading(false);
    }, [data]);

    // Event Handlers
    const handleCancel = () => {
        if (type === 'edit') {
            dispatch(setIsEdit(false));
        } else {
            //add
            dispatch(setIsAddNew(false));
        }
    };

    const deleteListing = (e) => {
        e.preventDefault();
        dispatch(openDeleteModal(data.docId));
    };

    // Form
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const updateInputs = (e, el) => {
        setInputs({ ...inputs, [el]: e.target.value });
    };
    const submitFunction = () => {
        setLoading(true);
        setTimeout(async () => {
            const exists = catOptions.options.some(
                (option) => option.value === inputs.campaign
            );
            if (!exists && inputs.campaign) {
                const newCamps = {
                    options: catOptions.options.concat({
                        label: inputs.campaign,
                        value: inputs.campaign,
                    }),
                };
                await updateDoc(doc(db, 'campaign', 'tags'), newCamps);
            }
            if (type === 'edit') {
                const docRef = doc(db, 'listings', data.docId);
                const updates = { ...inputs };

                // Update the redirect collection
                const redirectDocRef = doc(db, 'redirects', inputs.urlKey);
                await updateDoc(redirectDocRef, { url: inputs.destination });

                updates.qrOptions = {
                    style: qrCode.options.style || 'square',
                    colour: qrCode.options.colour || '#000000',
                    logo: qrCode.options.logo || false,
                };

                await updateDoc(docRef, updates);
                dispatch(setIsEdit(false));
            } else {
                //add
                const urlKeyExists = usedUrlKeys.includes(inputs.urlKey);
                if (!urlKeyExists) {
                    const newDocRef = doc(collection(db, 'listings'));
                    inputs.docId = newDocRef.id;
                    inputs.date_added = formatDate(new Date());
                    inputs.deleted = false;

                    inputs.qrOptions = {
                        style: qrCode.options.style || 'square',
                        colour: qrCode.options.colour || '#000000',
                        logo: qrCode.options.logo || false,
                    };

                    await setDoc(newDocRef, inputs);

                    // Add to redirect collection
                    const redirectDocRef = doc(db, 'redirects', inputs.urlKey);
                    await setDoc(redirectDocRef, {
                        id: newDocRef.id,
                        url: inputs.destination,
                    });

                    dispatch(setIsAddNew(false));
                } else {
                    setError('urlKey', {
                        type: 'manual',
                        message: 'This URL Key has already been used',
                    });
                }
            }
            setLoading(false);
        }, 1000);
    };

    useEffect(() => {
        if (
            inputs.destination &&
            (inputs.destinationType === QR_DESTINATION_TYPES.IMAGE ||
                inputs.destinationType === QR_DESTINATION_TYPES.PDF)
        ) {
            getDoc(doc(db, FILE_FOLDERS.FILES, inputs.destination)).then(
                (doc) => {
                    if (doc.exists) {
                        setDestinationFile({ ...doc.data(), docId: doc.id });
                    }
                }
            );
        } else {
            setDestinationFile(null);
        }
    }, [inputs.destinationType, inputs.destination]);

    const setNewDestinationType = (type) => {
        setInputs({ ...inputs, destinationType: type, destination: '' });
    };

    const handleFileUpload = (file) => {
        setInputs({ ...inputs, destination: file.docId });
    };

    return (
        <QrItemContainer
            component="form"
            onSubmit={handleSubmit(() => {
                submitFunction();
            })}
        >
            <QrItemHeader
                title={type === 'edit' ? 'Edit' : 'Add New'}
                leftActions={
                    <DestinationTypePicker
                        destinationType={inputs.destinationType}
                        setNewDestinationType={setNewDestinationType}
                    />
                }
                rightActions={
                    <>
                        <IconLink
                            icon={<FontAwesomeIcon icon={faCheck} />}
                            text="Submit"
                            onClick={handleSubmit(() => {
                                submitFunction();
                            })}
                        />
                        <IconLink
                            icon={<FontAwesomeIcon icon={faX} />}
                            text="Cancel"
                            onClick={handleCancel}
                        />
                    </>
                }
            />
            {loading ? (
                <Box p={2}>
                    <Loading color="black.main" />
                </Box>
            ) : (
                <QrItemInner>
                    <Box display="flex" flexWrap="wrap">
                        <Box
                            sx={{
                                width: '100%',
                                [theme.breakpoints.up('sm')]: { width: '75%' },
                            }}
                        >
                            <LabelledInput
                                label="URL Key"
                                name="urlKey"
                                value={inputs.urlKey}
                                pat={true}
                                mes="No special characters or spaces allowed"
                                placeholder="########"
                                errors={errors}
                                register={register}
                                updateInputs={updateInputs}
                                isBordered={type !== 'edit'}
                            />
                            <LabelledInput
                                label="Name"
                                name="name"
                                value={inputs.name}
                                placeholder="Brand Campaign Name"
                                errors={errors}
                                register={register}
                                updateInputs={updateInputs}
                                isBordered={type !== 'edit'}
                            />
                            {inputs.destinationType === 'url' ? (
                                <LabelledInput
                                    label="Destination"
                                    name="destination"
                                    value={inputs.destination}
                                    placeholder="https://www.yourlinkhere.com"
                                    errors={errors}
                                    register={register}
                                    updateInputs={updateInputs}
                                    isUrl
                                    isBordered={type !== 'edit'}
                                />
                            ) : (
                                <LabelledFileInput
                                    label="Destination"
                                    file={destinationFile}
                                    uploadType={inputs.destinationType}
                                    showFileUploadModal={showFileUploadModal}
                                    setShowFileUploadModal={
                                        setShowFileUploadModal
                                    }
                                    handleFileUpload={handleFileUpload}
                                    isBordered={type !== 'edit'}
                                />
                            )}
                            {type === 'edit' && (
                                <Box mt={3}>
                                    <LabelledUrlInput
                                        label="URL"
                                        urlKey={inputs.urlKey}
                                    />
                                    <LabelledInput
                                        label="Date Added"
                                        name="date_added"
                                        value={inputs.date_added}
                                        placeholder="Date"
                                        errors={errors}
                                        register={register}
                                        updateInputs={updateInputs}
                                    />
                                    <LabelledInputContainer label="Visits">
                                        <Typography flexGrow={1} ml={2}>
                                            {inputs.visits || 0}
                                        </Typography>
                                    </LabelledInputContainer>
                                </Box>
                            )}
                            <Grid alignItems="center" container={true}>
                                <Box pr={2}>
                                    <Typography
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                    >
                                        Tag:
                                    </Typography>
                                </Box>
                                <Auto
                                    catOptions={catOptions}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                />
                            </Grid>
                        </Box>
                        {type === 'edit' && (
                            <Box
                                align="right"
                                sx={{
                                    width: '100%',
                                    [theme.breakpoints.up('sm')]: {
                                        width: '25%',
                                    },
                                }}
                            >
                                <QrCode
                                    showFileExtControls
                                    urlKey={inputs.urlKey}
                                    filename={constructDownloadName(
                                        inputs.name,
                                        inputs.date_added
                                    )}
                                />
                            </Box>
                        )}
                    </Box>

                    {type === 'edit' && (
                        <QrItemFooter
                            leftActions={
                                <>
                                    <IconLink
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        text="Update B!INK"
                                        isFooterLink
                                        onClick={handleSubmit(() => {
                                            submitFunction();
                                        })}
                                    />
                                    <IconLink
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faPaintBrush}
                                                size="sm"
                                                color={theme.palette.grey.lite}
                                            />
                                        }
                                        text={'Customise QR Code'}
                                        isFooterLink
                                        onClick={() =>
                                            setParentView(QR_VIEWS.CUSTOMISE)
                                        }
                                    />
                                    <IconLink
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                                size="sm"
                                                color={theme.palette.grey.lite}
                                            />
                                        }
                                        text={'Details'}
                                        isFooterLink
                                        iconSide="right"
                                        onClick={() =>
                                            setParentView(QR_VIEWS.DETAILS)
                                        }
                                    />
                                </>
                            }
                            rightActions={
                                <IconLink
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    text="Delete"
                                    isFooterLink
                                    onClick={(e) => deleteListing(e)}
                                />
                            }
                        />
                    )}

                    <ConfirmDeleteListingModal data={data} />
                </QrItemInner>
            )}
        </QrItemContainer>
    );
};

function mapStateToProps(state) {
    return {
        campaign: state.Globals.campaign,
        edit: state.Globals.isEdit,
        addNew: state.Globals.isAddNew,
        qrCode: state.QrCode,
        baseUrl: state.Globals.baseUrl,
    };
}

export default connect(mapStateToProps)(QrViewAddEdit);
